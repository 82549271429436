import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import NavBar from './Components/NavBar';
import About from './Pages/About';
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';
import Room from './Pages/Room';
import Menu from './Pages/Menu';
import Blog from './Pages/Blog';
import RightIcon from './Components/RightSideIcon';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <RightIcon/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/best-beach-resort-bhogave" element={<About />} />
          <Route path="/resort-near-beach-bhogave" element={<Gallery />} />
          <Route path="/best-resorts-bhogave-beach" element={<Room />} />
          <Route path="/best-resort-near-beach-bhogave" element={<Menu />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/resort-in-bhogave" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
