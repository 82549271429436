import React, { useState } from "react";
import "../Css/Contact.css";
import Footer from "../Components/Footer";
import { MetaTags } from "react-meta-tags";
import TimePicker from "react-bootstrap-time-picker";
import Zoom from "react-reveal/Zoom";
import Bounce from "react-reveal/Bounce";
import Arrow from "../img/arrow.webp";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";

const Contact = () => {
  const [errors, setErrors] = useState({
    custName: "",
    custEmail: "",
    custContact: "",
    custMessage: "",
  });

  const handleValidation = () => {
    let isValid = true;
    const newErrors = {
      custName: "",
      custEmail: "",
      custContact: "",
      custPerson: "",
      custMessage: "",
    };
    if (!custName.trim()) {
      isValid = false;
      newErrors.custName = "Name is required";
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!custEmail.match(emailRegex)) {
      isValid = false;
      newErrors.custEmail = "Email is not valid";
    }

    if (!custContact.trim()) {
      isValid = false;
      newErrors.custContact = "Phone is required";
    } else if (!/^\d{10}$/.test(custContact)) {
      isValid = false;
      newErrors.custContact = "Phone must have 10 digits";
    }
    if (!custPerson.trim()) {
      isValid = false;
      newErrors.custPerson = "Enter Total People";
    }

    if (!custMessage.trim()) {
      isValid = false;
      newErrors.custMessage = "Write a Message";
    }

    setErrors(newErrors);
    return isValid;
  };
  // ============ Mail function ==========
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custPerson, setCustPerson] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // ============== Mail Print ======
  const ServForm = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      var body =
        '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#6e3b70">Ocean Delight</h2><p>Hello Ocean Delight,</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custName +
        '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custEmail +
        '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custContact +
        '</td></tr><tr><td style="text-align:left"><strong>Total People:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custPerson +
        '</td></tr><tr><td style="text-align:left"><strong>Start Date:</strong></td><td style="text-align:left;color:#6e3b70">' +
        startDate +
        '</td></tr><tr><td style="text-align:left"><strong>End Date:</strong></td><td style="text-align:left;color:#6e3b70">' +
        endDate +
        '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custMessage +
        '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#6e3b70;padding-left:5px">Ocean Delight</span>.</p></div></body></html>';
      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          // toEmail: "princygrwl4@gmail.com",
          toEmail: "oceandelight2022@gmail.com",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName: "Ocean-Delight",
          accountLeadSource: "https://oceandelight.co.in/",
          accountLeadName: custName,
          // accountLeadEmail: custEmail,
        },
        function (dataa, status) {
          console.log("data :" + dataa);
          console.log("name:" + custName);
          console.log("name:" + custEmail);
        }
      );

      alert("Your Form has Submitted Our team will contact with You  soon.");
      e.preventDefault();
      return false;
    }
  };
  return (
    <>
           {/* ========= Seo start========== */}
         {/* ========= Seo start========== */}
      <MetaTags>
        <title>Resort in Bhogave | Ocean Delight </title>
        <meta name="title" content="Resort in Bhogave | Ocean Delight  " />
        <meta property="og:title" content="Resort in Bhogave | Ocean Delight " />
        <meta name="description" content="Ocean Delight the beach resort is the best Resort in Bhogave. Book your perfect beachside escape today!" />
       
        <meta property="og:description" content="Ocean Delight the beach resort is the best Resort in Bhogave. Book your perfect beachside escape today!" />
        <meta name="keywords" content="Beach Resort in Bhogave,
 Best Beach Resort in Bhogave,
Best Resorts in Bhogave Beach,
 Best Resort Near Beach in Bhogave,
Resort Near Beach in Bhogave,
Resort in Bhogave" />
        <link rel="canonical" href="https://oceandelight.co.in/resort-in-bhogave" />
      </MetaTags>
      {/* ======== Seo end ======= */}

      {/* ========== Contact Banner ======== */}
      <div className="Contact-banner">
        <div className="ContactBanner-Txt">
       
          <Zoom>
            <h1 style={{color:"#fff"}}>Resort in Bhogave</h1>
          </Zoom>
          {/* <p>
          For more details, please contact our resort in Bhogave.
          </p> */}
        </div>
      </div>

      

      {/* =========== Contact Section ========== */}
      <section className="Contact-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 contact-column">
              <div className="left">
                <div className="left-Txt">
                  <span className="left-title">Stay With Us</span>
                  <h2>Contact Detail</h2>
                  <p>Welcome to our resort in Bhogave, Contact Us page – the gateway to personalized service and seamless communication at our beach resort in Bhogave. We understand that your experience with us begins with a connection, and we are dedicated to making that connection as smooth and enriching as your stay.


Our team is here to assist you every step of the way. Whether you have questions about room availability, special requests, or want to share feedback, our contact form is designed to streamline communication. Simply provide the necessary details, and our attentive staff will ensure a prompt and tailored response to meet your needs.


</p><p className="d-none">For those seeking immediate assistance, our contact information is readily available. Reach out via email or give us a call – we're here to assist with reservations, event planning, or any inquiries you may have. Your comfort and satisfaction are of paramount importance to us, and we are committed to exceeding your expectations.


We value your feedback and welcome any insights you may have to enhance your experience. Our Contact Us page is not just a form – it's a direct line to a team dedicated to ensuring your stay with us is nothing short of exceptional.


</p><p className="d-none">In addition to contact details, you'll find our location information, making it effortless for you to navigate your way to our beachfront haven. We believe that communication should be as warm and inviting as the atmosphere we provide, and we invite you to connect with us to start your journey towards an unforgettable stay.


Thank you for choosing our beach resort in Bhogave. Your adventure begins here, and we look forward to being a part of creating memories that last a lifetime. 
</p>
                </div>
                <div className="contact-form">
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Address</h4>
                        <p>
                          <a href="https://maps.app.goo.gl/zgwijLuxiBCadvLs9">
                          At Post Bhogwe, Nr Mahapurush Mandir, Dist, Tal, Bhogwe, Maharashtra 416523
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Contact</h4>
                        <p>
                          <a href="tel:+91 9820592518">+91 9820592518</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Mail</h4>
                        <p>
                          <a href="mailto:oceandelight2022@gmail.com">
                            oceandelight2022@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mt-3">
                    <div className="col-2">
                      <div className="contact-icon">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="contact-desc">
                        <h4>Times</h4>
                        <p>
                          Mon to Fri : 8.00am to 5.00 pm <br />
                          Sat : 8.00am to 3.00 pm
                          <br /> Sun : closed
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 contact-content">
              <div className="right">
                <div className="right-Txt">
                  <h2>Enquiry Now</h2>
                </div>
                <div className="contact-form">
                <div className="row">
                  <div class="col-sm-6 mb-2">
                    <div class="form-group">
                      <label for="name">Your Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={custName}
                        onChange={(e) => setCustName(e.target.value)}
                      />
                      {errors.custName && (
                        <span className="error-text">{errors.custName}</span>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6 mb-2">
                    <div class="form-group">
                      <label for="name">Your Email</label>
                      <input
                        class="form-control"
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={custEmail}
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                      {errors.custEmail && (
                        <span className="error-text">{errors.custEmail}</span>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6 mb-2">
                    <div class="form-group">
                      <label for="name">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="contact"
                        placeholder="Contact No"
                        value={custContact}
                        onChange={(e) => setCustContact(e.target.value)}
                      />
                      {errors.custContact && (
                        <span className="error-text">{errors.custContact}</span>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6 mb-2">
                    <div class="form-group">
                      <label for="name">Total Person</label>
                      <input
                        className="form-control"
                        type="number"
                        name="person"
                        placeholder="Total People"
                        value={custPerson}
                        onChange={(e) => setCustPerson(e.target.value)}
                      />
                      {errors.custContact && (
                        <span className="error-text">{errors.custPerson}</span>
                      )}
                    </div>
                  </div>
                  <div class="col-sm-6 mb-2">
                    <div class="form-group">
                      <label for="name">Check In</label>
                      <DatePicker
                        selected={startDate}
                        className="form-control"
                        value={startDate}
                        onChange={(StartDate) => setStartDate(StartDate)}
                      />
                    </div>
                  </div>
                  <div class="col-sm-6 mb-2">
                    <div class="form-group">
                      <label for="name">Check Out</label>
                      <DatePicker
                        selected={endDate}
                        className="form-control"
                        value={endDate}
                        onChange={(EndDate) => setEndDate(EndDate)}
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <div class="form-group">
                      <label for="name">Message</label>
                      <textarea
                        class="form-control"
                        name="message"
                        placeholder="Message"
                        id="custMessage1"
                        value={custMessage}
                        rows={6}
                        onChange={(e) => setCustMessage(e.target.value)}
                      ></textarea>
                      {errors.custMessage && (
                        <span className="error-text">{errors.custMessage}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <div class="form-btn">
                      <button className="booking-btn" onClick={ServForm}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========= Contact Map =========== */}
      <div className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3836.197161566034!2d73.504473!3d15.951077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc00123000b7c61%3A0x7625089222d0bd77!2sOcean%20Delight%20-%20The%20Beach%20Resort!5e0!3m2!1sen!2sin!4v1696500911062!5m2!1sen!2sin"
          width="100%"
          height="100%"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
