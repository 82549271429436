import React, { useState, useEffect } from "react";
import "../Css/NavBar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GrFacebookOption, GrInstagram } from "react-icons/gr";
import NavDropdown from "react-bootstrap/NavDropdown";
import { RiWhatsappFill } from "react-icons/ri";
import Logo from "../img/logo.jpeg";
import { AiOutlineYoutube } from "react-icons/ai";

const NavBar = () => {
  const [color, setColor] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  // Function to handle Navbar collapse
  const handleNavbarCollapse = () => {
    setExpanded(false);
  };

  return (
    <div className="nav-bar-component">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div className="headerlink">
                <ul>
                  <li>
                    <i className="fa fa-phone" />{" "}
                    <span>
                      <a href="tel:+91 9820592518">+91 9820592518</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="headerlink">
                <ul>
                <li>
                    <i className="fa fa-envelope" />{" "}
                    <span>
                      <a href="mailto:jsfwl95@gmail.com">oceandelight2022@gmail.com</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="headerlink">
                <ul>
                  <li>
                    <i className="fa fa-map-marker" />{" "}
                    <span>
                      <a href="https://maps.app.goo.gl/zgwijLuxiBCadvLs9" target="blank">Luxury Resort in Sindhudurg</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
            <div className="header_sociallink">
              <ul>
                <li className="socialIcon">
                  <a href="https://www.facebook.com/people/Ocean-Delight-The-Beach-Resort/100078409775781/"><i className="fa fa-facebook"></i></a>
                      
                    </li>
                    <li className="socialIcon">
                      
                      <a href="https://www.google.com/maps/place/Ocean+Delight+-+The+Beach+Resort/@15.9511283,73.5043766,17z/data=!3m1!4b1!4m9!3m8!1s0x3bc00123000b7c61:0x7625089222d0bd77!5m2!4m1!1i2!8m2!3d15.9511283!4d73.5043766!16s%2Fg%2F11pz1v3hxh?entry=ttu"><i className="fa fa-google-plus"></i></a>
                    </li>
                    <li className="socialIcon">
                      <a href="https://www.instagram.com/oceandelightbhogwe/"> <i className="fa fa-instagram"></i></a>
                     
                    </li>
                    </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="NavSection navbarLarge">
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          className={color ? "NavBar NavBar-bg" : "NavBar"}
          expanded={expanded}
        >
          <Container>
          <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-center"
            >
              <Nav>
                <Link to="/" onClick={handleNavbarCollapse}>
                  Home
                </Link>
                <Link to="/best-beach-resort-bhogave" onClick={handleNavbarCollapse}>
                  About Us
                </Link>
                <Link to="/best-resorts-bhogave-beach" onClick={handleNavbarCollapse}>
                  Room
                </Link>
                <Link to="/best-resort-near-beach-bhogave" onClick={handleNavbarCollapse}>
                  Menu
                </Link>
              </Nav>
            </Navbar.Collapse>
              <Navbar.Brand href="#home">
                <Link to="/" onClick={handleNavbarCollapse}>
                  <div className="NavLogo">
                    <img src={Logo} alt="" />
                  </div>
                </Link>
              </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => setExpanded(!expanded)}
            />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-center"
            >
              <Nav>
                
                <Link to="/Blog" onClick={handleNavbarCollapse}>
                  Blog
                </Link>
                <Link to="/resort-near-beach-bhogave" onClick={handleNavbarCollapse}>
                  Gallery
                </Link>
                <Link to="/resort-in-bhogave" onClick={handleNavbarCollapse}>
                  Contact Us
                </Link>
              </Nav>
            </Navbar.Collapse>
            {/* <div className="navbar-Btn">
              <Link to="/Contact">Enquiry Now</Link>
            </div> */}
          </Container>
        </Navbar>
      </div>
      <div className="NavSection navbarSmall">
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          className={color ? "NavBar NavBar-bg" : "NavBar"}
          expanded={expanded}
        >
          <Container>
              <Navbar.Brand href="#home">
                <Link to="/" onClick={handleNavbarCollapse}>
                  <div className="NavLogo">
                    <img src={Logo} alt="" />
                  </div>
                </Link>
              </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => setExpanded(!expanded)}
            />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-center"
            >
              <Nav>
                <Link to="/" onClick={handleNavbarCollapse}>
                  Home
                </Link>
                <Link to="/About" onClick={handleNavbarCollapse}>
                  About Us
                </Link>
                <Link to="/Room" onClick={handleNavbarCollapse}>
                  Room
                </Link>
                <Link to="/Menu" onClick={handleNavbarCollapse}>
                  Menu
                </Link>
                <Link to="/Blog" onClick={handleNavbarCollapse}>
                  Blog
                </Link>
                <Link to="/Gallery" onClick={handleNavbarCollapse}>
                  Gallery
                </Link>
                <Link to="/Contact" onClick={handleNavbarCollapse}>
                  Contact Us
                </Link>
              </Nav>
            </Navbar.Collapse>
            {/* <div className="navbar-Btn">
              <Link to="/Contact">Enquiry Now</Link>
            </div> */}
          </Container>
        </Navbar>
      </div>

    </div>
  );
};

export default NavBar;
