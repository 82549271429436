// ========= Room Images ==========

import Room1 from "../img/room/room6.jpg";
import Room2 from "../img/room/room5.jpg";
import Room3 from "../img/room/room4.jpg";
import Room4 from "../img/room/room3.jpg";
import Room5 from "../img/room/room2.jpg";


// =========== Resort Images ==========
import Resort1 from "../img/gallery/resort.jpg";
import Resort2 from "../img/gallery/resort1.jpg";
import Resort3 from "../img/gallery/resort2.jpg";
import Resort4 from "../img/gallery/resort3.jpg";
import Resort5 from "../img/gallery/resort4.jpg";

// ============ Pool Images ==========
import Beach1 from "../img/gallery/beach.jpg";


// =========== Food Images ==============
import Food1 from "../img/gallery/food1.jpg";
import Food2 from "../img/gallery/food2.jpg";
import Food3 from "../img/gallery/food3.jpg";


const GalleryData = [
  {
      id:1,
      Category:"Room Gallery",
      EImage:Room1,
      EventName:"Room View",
      alt:"Resort Near Beach in Bhogave",
  },
  {
      id:1,
      Category:"Room Gallery",
      EImage:Room2,
      EventName:"Room Bed",
      alt:"Resort Near Beach in Bhogave",
  },
  {
      id:1,
      Category:"Room Gallery",
      EImage:Room3,
      EventName:"Wash Room",
      alt:"Resort Near Beach ",
  },
  {
      id:1,
      Category:"Room Gallery",
      EImage:Room4,
      EventName:"Wash Room",
      alt:" Beach resort in Bhogave",
  },
  {
      id:1,
      Category:"Room Gallery",
      EImage:Room5,
      EventName:"Room View",
      alt:"Resort Near Beach in Bhogave",   
  },
  {
      id:2,
      Category:"Resort Gallery",
      EImage:Resort1,
      EventName:"Resort",
      alt:"Resort Near Beach in Bhogave",
  },
  {
    id:2,
    Category:"Resort Gallery",
    EImage:Resort2,
    EventName:"Resort Area",
    alt:"Resort Near Beach in Bhogave",
},
{
    id:2,
    Category:"Resort Gallery",
    EImage:Resort3,
    EventName:"Resort view",
},
{
    id:2,
    Category:"Resort Gallery",
    EImage:Resort4,
    EventName:"Setting Area",
},
{
    id:2,
    Category:"Resort Gallery",
    EImage:Resort5,
    EventName:"Resort Gardining",
},
  {
      id:3,
      Category:"Private Beach",
      EImage:Beach1,
      EventName:"Beach",
  },
  {
      id:4,
      Category:"Food Gallery",
      EImage:Food1,
      EventName:"Food Items",
  },
  {
    id:4,
    Category:"Food Gallery",
    EImage:Food2,
    EventName:"Food Items",
},
{
    id:4,
    Category:"Food Gallery",
    EImage:Food3,
    EventName:"Food Items",
},
]

export default GalleryData;