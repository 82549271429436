import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Pagination } from "swiper/modules";
import "../Css/About.css";
import Footer from "../Components/Footer";
import CountUp from "react-countup";
import { MetaTags } from "react-meta-tags";
import About1 from "../img/about-img.jpeg";
import Zoom from "react-reveal/Zoom";
import Bounce from "react-reveal/Bounce";
import Playing_Area from "../img/activities/sea-view.jpg";
import Room from "../img/activities/room.jpeg";
import Beach from "../img/activities/beach.jpg";
import Parking_Area from "../img/activities/food.jpeg";
import Arrow from "../img/arrow.webp";
import Icon1 from "../img/room/room.png";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      {/* ========= Seo start========== */}
        {/* ========= Seo start========== */}
        <MetaTags>
        <title> Best Beach Resort in Bhogave | Ocean Delight  </title>
        <meta name="title" content=" Best Beach Resort in Bhogave | Ocean Delight " />
        <meta property="og:title" content=" Best Beach Resort in Bhogave | Ocean Delight " />
        <meta name="description" content="Unwind in paradise at the Best Beach Resort in Bhogave. Explore pristine beaches, indulge in luxury & make memories to last a lifetime. " />
       
        <meta property="og:description" content="Unwind in paradise at the Best Beach Resort in Bhogave. Explore pristine beaches, indulge in luxury & make memories to last a lifetime. " />
        <meta name="keywords" content="Beach Resort in Bhogave,
 Best Beach Resort in Bhogave,
Best Resorts in Bhogave Beach,
 Best Resort Near Beach in Bhogave,
Resort Near Beach in Bhogave,
Resort in Bhogave" />
        <link rel="canonical" href="https://oceandelight.co.in/best-beach-resort-bhogave" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== About Banner ==== */}
      <div className="About-banner">
        <div className="AboutBanner-Txt">
        
          <Zoom>
            <h1 style={{color:'#fff'}}> Best Beach Resort in Bhogave</h1>
          </Zoom>
          {/* <p>
            Ocean Delight Beach Resort in bhogave, a beautiful beach resort in a
            tropical paradise.
          </p> */}
        </div>
      </div>
       
      {/* =========== About Section =========== */}
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="AboutImg">
                <img src={About1} alt="" />
              </div>
              <div className="AboutTxt">
              <p>Ocean Delight, the Best Beach Resort in Bhogave is a beach front property located at Bhogwe Beach in Sindhudurg. This property has a wide Sea view with a Private Beach and is surrounded by hundreds of coconut trees. This is the place where you can unwind on the beautiful and quiet beach, play in the waves of calm waters of the Arabian Sea or relax on our hammocks reading your favorite books.
</p>
<p>The rooms are all strategically placed so that every room has a Sea View. The rooms are designed to be modern, spacious and built to meet city traveler’s standards.
</p>

              </div>
            </div>
            <div className="col-lg-6">
            <div className="sec-title">
                  {/* <span className="sub-title">Ocean Delight Resort</span> */}
                  <h2>Welcome To Ocean Delight Beach Resort</h2>
                  <p className="">At Ocean Delight the Best Beach Resort in Bhogave, people matter to us the most and that is why you will not witness artificial smiles or pre-taught hospitality gimmicks here. We understand the expectations of our guests and we treat you as our Friends and feel elated when you come back for the second time.
</p>
<p className="">We recommend browsing through the generous reviews we’ve received on Google by our guests for Ocean Delight, the best Beach Resort in Bhogave. We hope to make your vacation spectacular in all senses, through our stunning beach location, Cuisine and our people.
</p>
                  <p>
                  At Ocean Delight, people matter us the most and that is why you will not witness artificial smiles or pre-taught hospitality gimmicks here. We understand expectations of our guests and we treat you as our Friends and feel elated when you come back for the second time.
                  </p>
                  <div className="AboutTxt1">
              <p>We recommend browsing through the generous reviews we’ve received on Google by our guests for Ocean Delight Beach Resort at Sindhudurg, Bhogwe. We hope to make your vacation spectacular in all senses, through our stunning beach location, Cuisine and our people.</p>
              </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== Counter Section ============= */}
      <section className="about-counter">
        <div className="container">
          <div className="headTitle">
            <Bounce>
              <h2 className="text-white">What You Experience Here</h2>
            </Bounce>
            <p className="text-white">
            On the day of check-in, our guests receive a call from the property mainly to understand your expected time of arrival and to know your culinary preferences. We ensure all the food is not Stored or Refrigerated and always served Fresh to our Guests.
  </p>
            <p className="text-white">
            We help you arrange a boat to visit various scenic points like Tsunami Island, Golden Rocks, Nivati LightHouse/ Nivati Rocks, Dolphin Point and much more. If you’re more adventurous, we can help you arrange Water Sports like Scuba, Parasailing, Snorkeling & Jetskiing as well. We’re connected to some of the professional Scuba Trainers who also train you for deep dive Scuba, basis the requests.
   </p>
            <p className="text-white">
            A Stunning sunset view from the rooms over a sip of coffee.Enjoy a romantic beach side Candle Light dinner with your partner on special occasions, based on requests.A Night walk on the beach or on the pathway within the property surrounded by lush greenery.


         </p>
          </div>
          <div className="row">
          <div className="resortBtn mt-5">
                  <Link to="/Contact" className="exp-btn">
                    Enquiry Now
                  </Link>
                </div>
          </div>
          {/* <div className="row">
            <div className="col-md-3 col-6 mb-5">
              <div className="counter left_icn">
                <i class="fa fa-calendar" aria-hidden="true"></i>
                <span className="timer">
                  <span>
                    <CountUp end={15} duration={10} />
                  </span>
                </span>
                <label>Total Experience</label>
              </div>
            </div>
            <div className="col-md-3 col-6 mb-5">
              <div className="counter left_icn">
                <i class="fa fa-users" aria-hidden="true"></i>
                <span className="timer">
                  <span>
                    <CountUp end={1500} duration={5} />
                  </span>
                </span>
                <label>Total Client</label>
              </div>
            </div>
            <div className="col-md-3 col-6 mb-5">
              <div className="counter left_icn">
                <i className="fa fa-smile-o" aria-hidden="true" />
                <span className="timer">
                  <span>
                    <CountUp end={1342} duration={5} />
                  </span>
                </span>
                <label>Happy User</label>
              </div>
            </div>
            <div className="col-md-3 col-6 mb-5">
              <div className="counter left_icn">
                <i className="fa fa-comments" aria-hidden="true" />
                <span className="timer">
                  <span>
                    <CountUp end={956} duration={5} />
                  </span>
                </span>
                <label>People Review</label>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* ========= Story Area ======= */}
      {/* <section className="story-area">
        <div className="container">
          <div className="site-heading text-center">
            <Bounce>
              <h2 className="site-title">Resort Activities</h2>
            </Bounce>
            <div className="header-img-divider">
              <img src={Arrow} alt="birthday" />
            </div>
            <h4>Glance at our great resort</h4>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="story-timeline">
                <div className="story-circle-start" />
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="story-left-content">
                      <span className="story-icon">
                        <i className="material-icons-outlined">
                          sports_cricket
                        </i>
                      </span>
                      <div className="story-content">
                        <h3>Playing Area</h3>
                        <p>
                          Ocean Delight offers a wide selection of activities
                          and facilities for guests of all ages, and we are on
                          hand to help you experience Bhogave to the fullest.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="story-right-img">
                      <div className="story-img">
                        <img src={Playing_Area} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6 order-lg-1 order-2">
                    <div className="story-left-img">
                      <div className="story-img">
                        <img src={Room} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 order-lg-2 order-1">
                    <div className="story-right-content">
                      <span className="story-icon">
                        <i className="material-icons-outlined">
                          bedroom_parent
                        </i>
                      </span>
                      <div className="story-content">
                        <h3>Room Services</h3>
                        <p>
                          Our rooms are elegantly designed and provide a
                          luxurious experience. Our room service makes you feel
                          at home.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="story-left-content">
                      <span className="story-icon">
                        <i className="material-icons-outlined">pool</i>
                      </span>
                      <div className="story-content">
                        <h3> Swimming Pool</h3>
                        <p>
                          Our swimming pool offers the perfect setting for
                          relaxation and fun for the whole family at Ocean
                          Delight Beach Resort. Dive into the refreshing waters
                          of our main pool
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="story-right-img">
                      <div className="story-img">
                        <img src={Pool} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6 order-lg-1 order-2">
                    <div className="story-left-img">
                      <div className="story-img">
                        <img src={Parking_Area} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 order-lg-2 order-1">
                    <div className="story-right-content">
                      <span className="story-icon">
                        <i className="material-icons-outlined">drive_eta</i>
                      </span>
                      <div className="story-content">
                        <h3>Parking Area</h3>
                        <p>
                          Free parking is provided to guests at Ocean Delight
                          Resort, located in the parking garage across from the
                          resort.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6 offset-lg-6">
                    <span className="story-circle-start"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ======== New Stories ======== */}
      <div className="section-full">
        <div className="container">
          {/* <div className="site-heading text-center">
            <Bounce>
              <h2 className="site-title">Resort Activities</h2>
            </Bounce>
            <div className="header-img-divider">
              <img src={Arrow} alt="birthday" />
            </div>
            <h4>Glance at our great resort</h4>
          </div> */}
          <div className="our-story">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="our-story-pic-block" id="ourS2">
                  <div className="notification-animate">
                    <div className="story-left-img">
                      <div className="story-img">
                        <img src={Playing_Area} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="our-story-detail">
                  <div className="story-content">
                    <h3>Sunset View</h3>
                    <p>
                    Nestled along the pristine coastline, Sunset View at Ocean Delight Resort is a tranquil oasis where you can escape from the hustle and bustle of everyday life.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12 order-md-1 order-2">
                <div className="our-story-detail">
                  <div className="story-content">
                    <h3>Private Beach</h3>
                    <p>
                    A private beach is an exclusive shoreline area reserved for the use of a specific individual, group, or organization, offering seclusion and control over access.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 order-md-2 order-1">
                <div className="our-story-pic-block">
                  <div className="notification-animate">
                    <div className="story-right-img">
                      <div className="story-img">
                        <img src={Beach} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="our-story-pic-block" id="ourS2">
                  <div className="notification-animate">
                    <div className="story-left-img">
                      <div className="story-img">
                        <img src={Room} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="our-story-detail">
                  <div className="story-content">
                    <h3>Room Services</h3>
                    <p>
                      Our rooms are elegantly designed and provide a luxurious
                      experience. Our room service makes you feel at home.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12 order-md-1 order-2">
                <div className="our-story-detail">
                  <div className="story-content">
                    <h3>Delicious Food</h3>
                    <p>
                    Ocean Delight Food often specialize in providing a range of delicious food products at Ocean Delight Beach Resort.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 order-md-2 order-1">
                <div className="our-story-pic-block">
                  <div className="notification-animate">
                    <div className="story-right-img">
                      <div className="story-img">
                        <img src={Parking_Area} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =========  Testimonal Section ============  */}
      <section className="testimonalSection">
        <div className="container">
          <div className="site-heading text-center">
            <Bounce>
              <h2 className="site-title">What Our Guest's Say</h2>
            </Bounce>
            <div className="header-img-divider">
              <img src={Arrow} alt="birthday" />
            </div>
            {/* <h4>Glance at our great resort</h4> */}
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay, Navigation]}
            className="testimonalSwiper"
          >
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "The resort was very nice and beautiful. The rooms are clean
                    and tidy, very near to beach. As we open the windows we see
                    a beautiful beach without any crowd. Very calm and quit
                    place to stay.The food was very tasty and beautifully
                    served. The owner and staff were very polite and friendly."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="" />
                  </div>
                  <div className="client_name">
                    <h5>Shradha Gajbhiye </h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "Such an amazing location. Rooms were very clean and view
                    from the room was superb. The staff was so helpfull and
                    fulfilled our requests. We stayed for 2 nights and had their
                    food both the days. The taste is very authentic and cooked
                    in their local spices. I would suggest everyone to try this
                    place once"
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="" />
                  </div>
                  <div className="client_name">
                    <h5> Mrinal Shinde </h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                  "This resort is really Amazing.Right on the beach. Very spacious room and with very good Interior. From your Room you can easily view the Beatiful sea and Even sunset as well. Dolphins point is also in front of Beach. Very relax and less crowded place you can Enjoy your vacation. Food is also good."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="" />
                  </div>
                  <div className="client_name">
                    <h5>Amol Somani</h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                  "Beautiful location, right on the beach. The beach is peaceful and clean. The rooms are also clean and spacious. The owners are very polite and sweet. They were there for all our needs. Peaceful and calm location. This is gonna b our regualr getaway location. Good service and staff Very co operative."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="" />
                  </div>
                  <div className="client_name">
                    <h5>Sukhjeet Kaur</h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                  "This resort is really Amazing.Right on the beach. Very spacious room and with very good Interior. From your Room you can easily view the Beatiful sea and Even sunset as well. Dolphins point is also in front of Beach. Very relax and less crowded place you can Enjoy your vacation. Good service and staff Very co operative."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="" />
                  </div>
                  <div className="client_name">
                    <h5>Amol Somani</h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* ============== About Partner =========== */}
      {/* <section className="page-section">
        <div className="container">
          <div className="row">
            <Swiper
              slidesPerView={1}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="pratner"
            >
              <SwiperSlide>
                <div className="our-clients">
                  <div className="item">
                    <a href="#">
                      <img
                        className="img-fluid center-block"
                        src="http://themes.potenzaglobalsolutions.com/html/constro/images/clients/01.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-clients">
                  <div className="item">
                    <a href="#">
                      <img
                        className="img-fluid center-block"
                        src="http://themes.potenzaglobalsolutions.com/html/constro/images/clients/02.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-clients">
                  <div className="item">
                    <a href="#">
                      <img
                        className="img-fluid center-block"
                        src="http://themes.potenzaglobalsolutions.com/html/constro/images/clients/03.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-clients">
                  <div className="item">
                    <a href="#">
                      <img
                        className="img-fluid center-block"
                        src="http://themes.potenzaglobalsolutions.com/html/constro/images/clients/04.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-clients">
                  <div className="item">
                    <a href="#">
                      <img
                        className="img-fluid center-block"
                        src="http://themes.potenzaglobalsolutions.com/html/constro/images/clients/05.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-clients">
                  <div className="item">
                    <a href="#">
                      <img
                        className="img-fluid center-block"
                        src="http://themes.potenzaglobalsolutions.com/html/constro/images/clients/06.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section> */}
      {/* ========== Footer ========== */}
      <Footer />
    </>
  );
};

export default About;
