import React, { useRef, useState, useCallback } from "react";
import "../Css/Home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "../Css/Home.css";
import CountUp from "react-countup";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import About1 from "../img/about/about4.jpg";
import About2 from "../img/about/about2.jpeg";
import Zoom from "react-reveal/Zoom";
import Footer from "../Components/Footer";
import Service1 from "../img/attraction/nivati_fort.jpg";
import Service2 from "../img/attraction/tsunami.jpg";
import Service3 from "../img/attraction/golden.jpg";
import Service4 from "../img/attraction/dolphin.jpg";
import Service5 from "../img/attraction/sindhudurg-fort.jpg";
import Service6 from "../img/attraction/lighthouse.jpg";
import Icon1 from "../img/room/room.png";
import Bounce from "react-reveal/Bounce";
import Flip from "react-reveal/Flip";
import Arrow from "../img/arrow.webp";
import Gallery1 from "../img/room/room6.jpg";
import Gallery2 from "../img/gallery/beach.jpg";
import Gallery3 from "../img/gallery/resort4.jpg";
import Gallery4 from "../img/gallery/restaurant.jpg";
import Gallery5 from "../img/gallery/setting-area.jpeg";
import Room1 from "../img/room/room6.jpg";
import Room2 from "../img/room/room5.jpg";
import Room3 from "../img/room/room2.jpg";
import Room4 from "../img/room/room3.jpg";
import Room5 from "../img/room/room4.jpg";
import Room6 from "../img/room/room1.jpg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";

const Home = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const [errors, setErrors] = useState({
    custName: "",
    custEmail: "",
    custContact: "",
    custMessage: "",
  });

  const handleValidation = () => {
    let isValid = true;
    const newErrors = {
      custName: "",
      custEmail: "",
      custContact: "",
      custPerson: "",
      custMessage: "",
    };
    if (!custName.trim()) {
      isValid = false;
      newErrors.custName = "Name is required";
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!custEmail.match(emailRegex)) {
      isValid = false;
      newErrors.custEmail = "Email is not valid";
    }

    if (!custContact.trim()) {
      isValid = false;
      newErrors.custContact = "Phone is required";
    } else if (!/^\d{10}$/.test(custContact)) {
      isValid = false;
      newErrors.custContact = "Phone must have 10 digits";
    }
    if (!custPerson.trim()) {
      isValid = false;
      newErrors.custPerson = "Enter Total People";
    }

    if (!custMessage.trim()) {
      isValid = false;
      newErrors.custMessage = "Write a Message";
    }

    setErrors(newErrors);
    return isValid;
  };
  // ============ Mail function ==========
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custPerson, setCustPerson] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // ============== Mail Print ======
  const ServForm = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      var body =
        '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#6e3b70">Ocean Delight</h2><p>Hello Ocean Delight,</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custName +
        '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custEmail +
        '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custContact +
        '</td></tr><tr><td style="text-align:left"><strong>Total People:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custPerson +
        '</td></tr><tr><td style="text-align:left"><strong>Start Date:</strong></td><td style="text-align:left;color:#6e3b70">' +
        startDate +
        '</td></tr><tr><td style="text-align:left"><strong>End Date:</strong></td><td style="text-align:left;color:#6e3b70">' +
        endDate +
        '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custMessage +
        '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#6e3b70;padding-left:5px">Ocean Delight</span>.</p></div></body></html>';
      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          toEmail: "oceandelight2022@gmail.com",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName: "Ocean-Delight",
          accountLeadSource: "https://oceandelight.co.in/",
          accountLeadName: custName,
          // accountLeadEmail: custEmail,
        },
        function (dataa, status) {
          console.log("data :" + dataa);
          console.log("name:" + custName);
          console.log("name:" + custEmail);
        }
      );

      alert("Your Form has Submitted Our team will contact with You  soon.");
      e.preventDefault();
      return false;
    }
  };

  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>Beach Resort in Bhogave | Ocean Delight </title>
        <meta name="title" content="Beach Resort in Bhogave | Ocean Delight " />
        <meta property="og:title" content="Beach Resort in Bhogave | Ocean Delight " />
        <meta name="description" content="Escape to paradise at our Beach Resort in Bhogave. Unwind on pristine shores, relish world-class amenities & create timeless memories. Book your stay today!" />
       
        <meta property="og:description" content="Escape to paradise at our Beach Resort in Bhogave. Unwind on pristine shores, relish world-class amenities & create timeless memories. Book your stay today!" />
        <meta name="keywords" content="Beach Resort in Bhogave,
 Best Beach Resort in Bhogave,
Best Resorts in Bhogave Beach,
 Best Resort Near Beach in Bhogave,
Resort Near Beach in Bhogave,
Resort in Bhogave" />
        <link rel="canonical" href="https://oceandelight.co.in/" />
      </MetaTags>
      {/* ======== Seo end ======= */}

      {/* ============ Banner Section =========== */}
      <div className="bannerSection">
        <Swiper
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="Home_Banner"
        >
          <SwiperSlide>
            <div className="home_Banner1">
              <div className="homeBanner_Txt">
                <h1 className="">Beach Resort in Bhogave</h1>
                <Zoom>
                <h2>Luxury Living With Nature</h2>
                    {/* <h2>Booking Early Save More</h2> */}
                  </Zoom>
                {/* <p>
                  A private beach is an exclusive shoreline area reserved for
                  the use of a specific.
                </p> */}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="home_Banner2">
              <div className="homeBanner_Txt">
              <Zoom>
                  <h2>Feel Like Home</h2>
                </Zoom>
                {/* <p>
                  Our rooms have stunning views of the beach with all the modern
                  amenities.
                </p> */}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="home_Banner3">
              <div className="homeBanner_Txt">
                  {/* <Zoom>
                    <h2>Booking Early Save More</h2>
                  </Zoom> */}
                <Zoom>
                        <h2>Book Early Save More</h2>
                      </Zoom> 
                {/* <p>
                Ocean Delight Beach Resort in bhogave, a beautiful beach resort in a tropical paradise.
                </p> */}
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
       {/* =========== About Section =========== */}
       <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="aboutImg col-xl-6 col-lg-6">
              <div className="home-about">
                  <div className="img1">
                  <img
                    src={About2}
                    alt="beach resort"
                  />
                  </div>
                <div className="img2">
                  <img
                    src={About1}
                    alt="bhagove beach resort"
                  />
                </div>
              </div>
            </div>
            <div className="content-column col-lg-6">
              <div className="inner-column">
                <div className="sec-title">
                  <span className="sub-title">WELCOME TO OCEAN DELIGHT BEACH RESORT</span>
                  <h2>Be In The Moment With Ocean Delight</h2>
                  <p>
                 

Get transported to a softer, gentler world at our charming beach front property set amongst coconut trees on the quiet and serene Bhogwe Beach in Sindhudurg.


Escape to paradise at  OceanDelight Beach Resort in Bhogave, where luxury and natural beauty come together to create an unforgettable vacation experience. Our beachfront resort is located on the beautiful Bhogwe Beach, also known for one of Blue-flag nominated cleanest beaches in Maharashtra at Arabian Sea and its crystal-clear waters.


</p><p>In our Beach Resort in Bhogave, where sun-kissed sands meet unparalleled luxury. Nestled along the pristine coastline, our resort offers a haven of relaxation and adventure. Immerse yourself in the soothing rhythm of the waves and indulge in a seamless blend of comfort and nature.
</p>



<p className="d-none">Our thoughtfully designed accommodations provide a retreat from the ordinary, with panoramic views of the azure sea. Savor exquisite culinary delights at our beachfront restaurants, offering a symphony of flavors inspired by local and international cuisine. Dive into a world of recreation with water sports experiences. Our commitment to sustainability ensures a harmonious relationship with the environment, allowing you to unwind guilt-free.


Whether you seek a romantic getaway, family escape, or thrilling aquatic adventures, our beach resort invites you to create timeless memories against the backdrop of sunsets and endless horizons. Welcome to a Beach Resort in Bhogave where every moment is as extraordinary as the ocean breeze.
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========= AminitieSection ============  */}
      <section className="aminitiesSection">
        <div className="container">
          <div className="site-heading text-center">
            <h2 className="site-title">Our Facilities</h2>
            <div className="header-img-divider">
              <img src={Arrow} alt="best beach resort" />
            </div>
            {/* <h4>Glance at our great resort</h4> */}
          </div>
          {/* <div className="headTitle">
          <Bounce>
            <h2>our <span>Amenities</span></h2>
            </Bounce>
            <p>
              Duis diam eros, dignissim sed condimentum ac, vehicula nec nisl.
              Suspendisse condimentum libero tempus, accumsan augue et, varius
              dui. Morbi justo tortor, tincidunt ornare magna ut, molestie
              mattis enim.
            </p>
          </div> */}
          <div className="row row-cols-lg-6 row-cols-md-4 row-cols-sm-3 row-cols-3 d-flex justify-content-center align-items-center">
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">wifi</span>
                <h3>Complimentary Wi-Fi</h3>
              </div>
            </div>
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">beach_access</span>
                <h3>Private Beach</h3>
              </div>
            </div>
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">bedroom_parent</span>
                <h3>Air Conditioner Room</h3>
              </div>
            </div>
            {/* <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">ondemand_video</span>
                <h3>Television</h3>
              </div>
            </div> */}
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">drive_eta</span>
                <h3>Pick Up & Drop</h3>
              </div>
            </div>
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">restaurant</span>
                <h3>Restaurant</h3>
              </div>
            </div>
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">solar_power</span>
                <h3>Power Backup</h3>
              </div>
            </div>
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">
                  table_restaurant
                </span>
                <h3>Open Sky Dining</h3>
              </div>
            </div>
            {/* <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">
                  cleaning_services
                </span>
                <h3>Daily Housekeeping</h3>
              </div>
            </div> */}
            <div className="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div className="aminitiCard">
                <span className="material-icons-outlined">
                  local_fire_department
                </span>
                <h3>Bonfire</h3>
              </div>
            </div>
            <div class="col mb-md-5 mb-2 d-flex justify-content-center align-items-center">
              <div class="aminitiCard">
                <span class="material-icons-outlined">water_drop</span>
                <h3>Water Sports</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========= Room Services Section ========= */}
      <section className="roomService">
        <div className="container">
          <div className="site-heading text-center">
            <Bounce>
              <h2 className="site-title">Room Features</h2>
            </Bounce>
            <div className="header-img-divider">
              <img src={Arrow} alt="best resort bhogave" />
            </div>
            {/* <h4>Glance at our great resort</h4> */}
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Swiper
                spaceBetween={10}
                navigation={true}
                modules={[Navigation]}
                className="room2"
              >
                <SwiperSlide>
                  <div className="roomImg-long">
                    <img src={Room1} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-long">
                    <img src={Room2} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-long">
                    <img src={Room3} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-long">
                    <img src={Room4} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-long">
                    <img src={Room5} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-long">
                    <img src={Room6} />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-lg-6">
              <div className="home_roomDesc">
                <div className="home_roomTxt">
                  <h2>Description of Room</h2>
                  <p>
                  Welcome to Ocean Delight Beach Resort in Bhogave. Our rooms have stunning views of the beach. Our beach resort in Bhogave is designed to offer guests an unforgettable beach experience and is equipped with all the modern amenities required for a comfortable stay. From comfortable rooms for solo travelers to spacious rooms for families and groups, we offer something for every guest.

                  </p>
                  <Link t0="/" className="home_roomBtn">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========= Resort Section ========= */}
      <section className="resot-activities">
        <div className="container">
          <div className="site-heading text-center">
            <Bounce>
              <h2 className="site-title">Resort Activities</h2>
            </Bounce>
            <div className="header-img-divider">
              <img src={Arrow} alt="resort with beach" />
            </div>
            {/* <h4>Glance at our great resort</h4> */}
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="Resort_txt">
                <div className="resortHead">
                  <h2>Activities of Resort</h2>
                </div>
                <div className="resortPara">
                  <p>
                  Ocean Delight  Beach Resort in Bhogave offers a wide selection of activities and facilities for guests of all ages, and we are on hand to help you experience Bhogave to the fullest.






                  </p>
                  <p>
                  At Ocean Delight Beach Resort in Bhogave, we take pride in offering an extensive range of activities and top-notch facilities tailored to suit guests of all ages. Our commitment is to ensure that your stay in Bhogave is not only memorable but also filled with exciting opportunities to create lasting memories.  </p>
                </div>
                <div className="resortBtn mt-5">
                  <Link to="/Contact" className="res-btn">
                    Enquiry Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="row nasir-welboxes">
                <div className="single_wel_cont col-sm-6">
                  <Flip left>
                    <a href="#" className="wel-box">
                      <div className="icon-box">
                        <i className="material-icons-outlined">
                          sports_cricket
                        </i>
                      </div>
                      <h4>playing area</h4>
                      <div className="overlay transition3s">
                        <div className="icon_position_table">
                          <div className="icon_container border_round">
                            {/* <h2>playing area</h2> */}
                            <p>
                              Ocean Delight offers a wide selection of
                              activities and facilities for guests of all ages,
                              and we are on hand to help you experience Bhogave
                              to the fullest.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </Flip>
                </div>
                <div className="single_wel_cont col-sm-6">
                  <Flip left>
                    <a href="#" className="wel-box">
                      <div className="icon-box">
                        <i className="material-icons-outlined">beach_access</i>
                      </div>
                      <h4>Private Beach</h4>
                      <div className="overlay transition3s">
                        <div className="icon_position_table">
                          <div className="icon_container border_round">
                            {/* <h2>swimming pool</h2> */}
                            <p>
                              A private beach is an exclusive shoreline area
                              reserved for the use of a specific individual,
                              group, or organization, offering seclusion and
                              control over access.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </Flip>
                </div>
                <div className="single_wel_cont col-sm-6">
                  <Flip left>
                    <a href="#" className="wel-box">
                      <div className="icon-box">
                        <i class="fa fa-ship"></i>
                        {/* <i className="material-icons-outlined">nightlife</i> */}
                      </div>
                      <h4>Boat Ride</h4>
                      <div className="overlay transition3s">
                        <div className="icon_position_table">
                          <div className="icon_container border_round">
                            {/* <h2>Boat Ride</h2> */}
                            <p>
                              where you can admire picturesque scenery and
                              unwind in tranquility. Our resort's boat rides
                              offer a peaceful escape amidst nature's beauty.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </Flip>
                </div>
                <div className="single_wel_cont col-sm-6">
                  <Flip left>
                    <a href="#" className="wel-box">
                      <div className="icon-box">
                        <i className="material-icons-outlined">yard</i>
                      </div>
                      <h4>Garden</h4>
                      <div className="overlay transition3s">
                        <div className="icon_position_table">
                          <div className="icon_container border_round">
                            {/* <h2>Garden</h2> */}
                            <p>
                              Our resort's lush gardens provide a peaceful
                              escape for guests seeking relaxation and natural
                              beauty.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </Flip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* =========  Attrection Section ============  */}
      <section className="nearbyAttrection">
        <div className="container">
          <div className="site-heading text-center">
            <Bounce>
              <h2 className="site-title">Near By Attraction</h2>
            </Bounce>
            <div className="header-img-divider">
              <img src={Arrow} alt="beach resort" />
            </div>
            {/* <h4>Glance at our great resort</h4> */}
          </div>
          <div className="row">
            {/* <div className="col-lg-4 col-12 mt-md-5 mt-3">
              <Flip left>
                <div
                  className="attrectionCard aos-init aos-animate"
                  data-aos="fade-right"
                >
                  <div className="attrectionImg">
                    <img src={Service1} alt=" Beach Area" />
                    <h3>Nivti Fort</h3>
                  </div>
                  <div className="attractionBody">
                    <h3>Nivti Fort</h3>
                    <p>
                      Nivti Fort, a hidden gem in the Bhogave area. This
                      centuries-old fort offers stunning panoramic views and a
                      glimpse into the region's rich history. A must-visit for
                      history buffs and those seeking a unique adventure near
                      Gorai Road.
                    </p>
                  </div>
                </div>
              </Flip>
            </div> */}
            <div className="col-lg-4 col-12 mt-md-5 mt-3">
              <Flip left>
                <div
                  className="attrectionCard aos-init aos-animate"
                  data-aos="zoom-in-up"
                >
                  <div className="attrectionImg">
                    <img src={Service2} alt=" Resort Near Beach " />
                    <h3>Tsunami Island</h3>
                  </div>
                  <div className="attractionBody">
                    <h3>Tsunami Island</h3>
                    <p>
                      This place has apparently been formed after Tsunami that
                      struck in 2004. While there has been lots of destruction
                      everywhere in the world due to Tsunami, this place has
                      been unique in a way that its formed only due to Tsunami
                      and now its bustling with tourists and people earning
                      their living.
                    </p>
                  </div>
                </div>
              </Flip>
            </div>
            <div className="col-lg-4 col-12 mt-md-5 mt-3">
              <Flip left>
                <div
                  className="attrectionCard aos-init aos-animate"
                  data-aos="fade-left"
                >
                  <div className="attrectionImg">
                    <img src={Service3} alt=" Resort Near Temple" />
                    <h3>Golden Rocks</h3>
                  </div>
                  <div className="attractionBody">
                    <h3>Golden Rocks</h3>
                    <p>
                      Golden rock as the name suggests is immensely beautiful
                      and treat to watch. It is in Malvan district off the
                      Devbag beach near Tsunami Island. The place is full of
                      large and titanic rocks which appear Golden in colour
                      especially with early morning sun rays falling on the same
                      ! Golden rock is surrounded by sea.
                    </p>
                  </div>
                </div>
              </Flip>
            </div>
            {/* <div className="col-lg-4 col-12 mt-md-5 mt-3">
              <Flip left>
                <div
                  className="attrectionCard aos-init aos-animate"
                  data-aos="fade-left"
                >
                  <div className="attrectionImg">
                    <img src={Service4} alt=" Resort Near Temple" />
                    <h3>Dolphin Point</h3>
                  </div>
                  <div className="attractionBody">
                    <h3>Dolphin Point</h3>
                    <p>
                      Experience the magic of dolphin watching at Dolphin Point,
                      Nivati Beach, Maharashtra. Marvel at the playful
                      acrobatics of these marine creatures in their natural
                      habitat, all while enjoying the pristine coastal beauty
                      and tranquility of this picturesque spot.
                    </p>
                  </div>
                </div>
              </Flip>
            </div> */}
            {/* <div className="col-lg-4 col-12 mt-md-5 mt-3">
              <Flip left>
                <div
                  className="attrectionCard aos-init aos-animate"
                  data-aos="fade-left"
                >
                  <div className="attrectionImg">
                    <img src={Service5} alt=" Resort Near Temple" />
                    <h3>Sindhudurg Fort</h3>
                  </div>
                  <div className="attractionBody">
                    <h3>Sindhudurg Fort</h3>
                    <p>
                      If you are looking to visit Sindhudurg Fort, you will need
                      to travel to the Sindhudurg district in Maharashtra, which
                      is quite a distance from Bhogave. Please ensure you have
                      the correct location and travel plans in place if you
                      intend to visit the fort.
                    </p>
                  </div>
                </div>
              </Flip>
            </div> */}
            <div className="col-lg-4 col-12 mt-md-5 mt-3">
              <Flip left>
                <div
                  className="attrectionCard aos-init aos-animate"
                  data-aos="fade-left"
                >
                  <div className="attrectionImg">
                    <img src={Service6} alt="beach resort" />
                    <h3>Nivati Lighthouse</h3>
                  </div>
                  <div className="attractionBody">
                    <h3>Nivati Lighthouse</h3>
                    <p>
                      There are actually two lighthouses in the vicinity: the
                      Old Nivti Lighthouse and the New Nivti Lighthouse. The Old
                      Nivti Lighthouse is no longer in active use and serves
                      more as a historical site. The New Nivti Lighthouse, on
                      the other hand, is the operational lighthouse that guides
                      ships and boats along the coastline
                    </p>
                  </div>
                </div>
              </Flip>
            </div>
          </div>
        </div>
      </section>
      {/* ========== Counter Section ============= */}
      <section className="counter-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="counter_txt">
                <Bounce>
                  <h2>The Best Resort Ever</h2>
                </Bounce>
                <p>
                Ocean Delight Beach Resort in Bhogave is a beachfront property located at Bhogwe Beach in Sindhudurg. This property has a wide Sea view with a Private Beach and is surrounded by hundreds of coconut trees.
                </p>
                <div className="resortBtn mt-5">
                  <Link to="/Contact" className="exp-btn">
                    Enquiry Now
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="row">
                <div className="col-6 mb-5">
                  <div className="counter left_icn">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                    <span className="timer">
                      <span>
                        <CountUp end={15} duration={10} />
                      </span>
                    </span>
                    <label>Total Experience</label>
                  </div>
                </div>
                <div className="col-6 mb-5">
                  <div className="counter left_icn">
                    <i class="fa fa-users" aria-hidden="true"></i>
                    <span className="timer">
                      <span>
                        <span>
                          <CountUp end={1500} duration={5} />
                        </span>
                      </span>
                    </span>
                    <label>Total Client</label>
                  </div>
                </div>
                <div className="col-6 mb-5">
                  <div className="counter left_icn">
                    <i className="fa fa-smile-o" aria-hidden="true" />
                    <span className="timer">
                      <span>
                        <span>
                          <CountUp end={1342} duration={5} />
                        </span>
                      </span>
                    </span>
                    <label>Happy User</label>
                  </div>
                </div>
                <div className="col-6 mb-5">
                  <div className="counter left_icn">
                    <i className="fa fa-comments" aria-hidden="true" />
                    <span className="timer">
                      <span>
                        <span>
                          <CountUp end={956} duration={5} />
                        </span>
                      </span>
                    </span>
                    <label>People Review</label>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* =========== Gallery Section =========== */}
      {/* <section className="Gallery-Section">
        <div className="Gallery-Card">
          <div className="container">
            <div className="site-heading text-center">
              <Bounce>
                <h2 className="site-title">Our Gallery Services</h2>
              </Bounce>
              <div className="header-img-divider">
                <img src={Arrow} alt="birthday" />
              </div>
              <h4>Glance at our great resort</h4>
            </div>
            <div className="container">
              <Gallery photos={ProjectImages} onClick={openLightbox} />
              <ModalGateway>
                {viewerIsOpen ? (
                  <Modal onClose={closeLightbox}>
                    <Carousel
                      currentIndex={currentImage}
                      views={ProjectImages.map((x) => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title,
                      }))}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>
          </div>
        </div>
      </section> */}
      <section className="Gallery-Section">
        <div className="container">
          <div className="site-heading text-center">
            <Bounce>
              <h2 className="site-title">Our Gallery</h2>
            </Bounce>
            <div className="header-img-divider">
              <img src={Arrow} alt="beach resort" />
            </div>
            {/* <h4>Glance at our great resort</h4> */}
          </div>
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            modules={[Autoplay, Navigation]}
            className="GallerySwiper"
          >
            <SwiperSlide>
              <Flip left>
                <div className="gallerCard">
                  <div className="gallerImg">
                    <img src={Gallery1} alt=" Beach Area" />
                    <h3>Bed Room</h3>
                  </div>
                  <div className="gallerBody">
                    <h3>Bed Room</h3>
                    <p>
                      Our rooms are elegantly designed and provide a luxurious
                      experience. Our room service makes you feel at home.
                    </p>
                  </div>
                </div>
              </Flip>
            </SwiperSlide>
            <SwiperSlide>
              <Flip left>
                <div className="gallerCard">
                  <div className="gallerImg">
                    <img src={Gallery2} alt=" Beach Area" />
                    <h3>Private Beach</h3>
                  </div>
                  <div className="gallerBody">
                    <h3>Private Beach</h3>
                    <p>
                      A private beach is an exclusive shoreline area reserved
                      for the use of a specific individual, group, or
                      organization, offering seclusion and control over access.
                    </p>
                  </div>
                </div>
              </Flip>
            </SwiperSlide>
            <SwiperSlide>
              <Flip left>
                <div className="gallerCard">
                  <div className="gallerImg">
                    <img src={Gallery3} alt=" Beach Area" />
                    <h3>Garden Area</h3>
                  </div>
                  <div className="gallerBody">
                    <h3>Garden Area</h3>
                    <p>
                      Stroll through our meticulously landscaped gardens, a
                      serene oasis where you can reconnect with nature, enjoy
                      leisurely walks, and appreciate the vibrant flora.
                    </p>
                  </div>
                </div>
              </Flip>
            </SwiperSlide>
            <SwiperSlide>
              <Flip left>
                <div className="gallerCard">
                  <div className="gallerImg">
                    <img src={Gallery4} alt=" Beach Area" />
                    <h3>Restaurant Area</h3>
                  </div>
                  <div className="gallerBody">
                    <h3>Restaurant Area</h3>
                    <p>
                      Ocean Delight is a dining haven where the ocean's bounty
                      meets culinary delight. Our restaurant area offers a
                      unique and enchanting dining experience, combining a
                      captivating ambiance with a delectable menu that
                      celebrates the ocean's treasures.
                    </p>
                  </div>
                </div>
              </Flip>
            </SwiperSlide>
            <SwiperSlide>
              <Flip left>
                <div className="gallerCard">
                  <div className="gallerImg">
                    <img src={Gallery5} alt=" Beach Area" />
                    <h3>Balcony</h3>
                  </div>
                  <div className="gallerBody">
                    <h3>Balcony</h3>
                    <p>
                      Ocean Delight is a true oasis of tranquility, where the
                      beauty of the natural world converges with thoughtful
                      design to create an ambiance that is both inviting and
                      inspiring. Whether you're enjoying a romantic evening, a
                      family celebration
                    </p>
                  </div>
                </div>
              </Flip>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* =========  Testimonal Section ============  */}
      <section className="testimonalSection">
        <div className="container">
          <div className="site-heading text-center">
            <Bounce>
              <h2 className="site-title">What Our Guest's Say</h2>
            </Bounce>
            <div className="header-img-divider">
              <img src={Arrow} alt="ocean deight resort" />
            </div>
            {/* <h4>Glance at our great resort</h4> */}
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay, Navigation]}
            className="testimonalSwiper"
          >
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "The resort was very nice and beautiful. The rooms are clean
                    and tidy, very near to beach. As we open the windows we see
                    a beautiful beach without any crowd. Very calm and quit
                    place to stay.The food was very tasty and beautifully
                    served. The owner and staff were very polite and friendly."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="owner ocean delight" />
                  </div>
                  <div className="client_name">
                    <h5>Shradha Gajbhiye </h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "Such an amazing location. Rooms were very clean and view
                    from the room was superb. The staff was so helpfull and
                    fulfilled our requests. We stayed for 2 nights and had their
                    food both the days. The taste is very authentic and cooked
                    in their local spices. I would suggest everyone to try this
                    place once"
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="ocean delight resort" />
                  </div>
                  <div className="client_name">
                    <h5> Mrinal Shinde </h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "This resort is really Amazing.Right on the beach. Very
                    spacious room and with very good Interior. From your Room
                    you can easily view the Beatiful sea and Even sunset as
                    well. Dolphins point is also in front of Beach. Very relax
                    and less crowded place you can Enjoy your vacation. Food is
                    also good."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="ocean delight resort" />
                  </div>
                  <div className="client_name">
                    <h5>Amol Somani</h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "Beautiful location, right on the beach. The beach is
                    peaceful and clean. The rooms are also clean and spacious.
                    The owners are very polite and sweet. They were there for
                    all our needs. Peaceful and calm location. This is gonna b
                    our regualr getaway location. Good service and staff Very co
                    operative."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="ocean delight resort" />
                  </div>
                  <div className="client_name">
                    <h5>Sukhjeet Kaur</h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="test-cont">
                  <p>
                    "This resort is really Amazing.Right on the beach. Very
                    spacious room and with very good Interior. From your Room
                    you can easily view the Beatiful sea and Even sunset as
                    well. Dolphins point is also in front of Beach. Very relax
                    and less crowded place you can Enjoy your vacation. Good
                    service and staff Very co operative."
                  </p>
                </div>
                <div className="test-bot">
                  <div className="client_img">
                    <img src={About1} alt="ocean delight resort" />
                  </div>
                  <div className="client_name">
                    <h5>Amol Somani</h5>
                    <ul>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                      <li>
                        <i className="fa fa-star" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* =========== Form  Section ========== */}
      <section className="booking-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 booking-column">
              <div className="booking-left">
                <div className="booking-left-Txt">
                  <span className="left-title">Stay With Us</span>
                  <h2>Contact Detail</h2>
                </div>
                <div className="booking-form">
                  <div className="row">
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Your Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          value={custName}
                          onChange={(e) => setCustName(e.target.value)}
                        />
                        {errors.custName && (
                          <span className="error-text">{errors.custName}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Your Email</label>
                        <input
                          class="form-control"
                          type="text"
                          name="email"
                          value={custEmail}
                          onChange={(e) => setCustEmail(e.target.value)}
                        />
                        {errors.custEmail && (
                          <span className="error-text">{errors.custEmail}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Phone</label>
                        <input
                          className="form-control"
                          type="number"
                          name="contact"
                          value={custContact}
                          onChange={(e) => setCustContact(e.target.value)}
                        />
                        {errors.custContact && (
                          <span className="error-text">
                            {errors.custContact}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Total Person</label>
                        <input
                          className="form-control"
                          type="number"
                          name="person"
                          value={custPerson}
                          onChange={(e) => setCustPerson(e.target.value)}
                        />
                        {errors.custContact && (
                          <span className="error-text">
                            {errors.custPerson}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Check In</label>
                        <DatePicker
                          selected={startDate}
                          className="form-control"
                          value={startDate}
                          onChange={(StartDate) => setStartDate(StartDate)}
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div class="form-group">
                        <label for="name">Check Out</label>
                        <DatePicker
                          selected={endDate}
                          className="form-control"
                          value={endDate}
                          onChange={(EndDate) => setEndDate(EndDate)}
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div class="form-group">
                        <label for="name">Message</label>
                        <textarea
                          class="form-control"
                          name="message custMessage"
                          id="custMessage1"
                          value={custMessage}
                          rows={6}
                          onChange={(e) => setCustMessage(e.target.value)}
                        ></textarea>
                        {errors.custMessage && (
                          <span className="error-text">
                            {errors.custMessage}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div class="form-btn">
                        <button className="booking-btn" onClick={ServForm}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 booking-content">
              <div className="booking-right">
                <div className="bookingsec-title">
                  <span className="sub-title">Ocean Delight Resort</span>
                  <h2>Contact Us</h2>
                </div>
                <h4>Address</h4>
                <p>
                  At Post Bhogwe, Nr Mahapurush Mandir, Dist, Tal, Bhogwe,
                  Maharashtra 416523
                </p>

                <h4 className="mt-3">Contact</h4>
                <p>
                  <a href="tel:+91 9820592518">+91 9820592518</a>
                </p>

                <h4 className="mt-3">Mail</h4>
                <p>
                  <a href="mailto:oceandelight2022@gmail.com">
                    oceandelight2022@gmail.com
                  </a>
                </p>
                {/* <h4 className="mt-3">Times</h4>
                <p>
                  Mon to Fri : 8.00am to 5.00 pm <br />
                  Sat : 8.00am to 3.00 pm
                  <br /> Sun : closed
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ========== Footer ========== */}
      <Footer />
    </>
  );
};

export default Home;
