import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "../Css/Room.css";
import Room1 from "../img/room/room6.jpg";
import Room2 from "../img/room/room5.jpg";
import Room3 from "../img/room/room2.jpg";
import Room4 from "../img/room/room3.jpg";
import Room5 from "../img/room/room4.jpg";
import Room6 from "../img/room/room1.jpg";
import Footer from "../Components/Footer";
import { Zoom } from "react-reveal";
import Bounce from "react-reveal/Bounce";
import Arrow from "../img/arrow.webp";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";

const Room = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [errors, setErrors] = useState({
    custName: "",
    custEmail: "",
    custContact: "",
    custMessage: "",
  });

  const handleValidation = () => {
    let isValid = true;
    const newErrors = {
      custName: "",
      custEmail: "",
      custContact: "",
      custPerson: "",
      custMessage: "",
    };
    if (!custName.trim()) {
      isValid = false;
      newErrors.custName = "Name is required";
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!custEmail.match(emailRegex)) {
      isValid = false;
      newErrors.custEmail = "Email is not valid";
    }

    if (!custContact.trim()) {
      isValid = false;
      newErrors.custContact = "Phone is required";
    } else if (!/^\d{10}$/.test(custContact)) {
      isValid = false;
      newErrors.custContact = "Phone must have 10 digits";
    }
    if (!custPerson.trim()) {
      isValid = false;
      newErrors.custPerson = "Enter Total People";
    }

    if (!custMessage.trim()) {
      isValid = false;
      newErrors.custMessage = "Write a Message";
    }

    setErrors(newErrors);
    return isValid;
  };
  // ============ Mail function ==========
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custPerson, setCustPerson] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // ============== Mail Print ======
  const ServForm = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      var body =
        '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#6e3b70">Ocean Delight</h2><p>Hello Ocean Delight,</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custName +
        '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custEmail +
        '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custContact +
        '</td></tr><tr><td style="text-align:left"><strong>Total People:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custPerson +
        '</td></tr><tr><td style="text-align:left"><strong>Start Date:</strong></td><td style="text-align:left;color:#6e3b70">' +
        startDate +
        '</td></tr><tr><td style="text-align:left"><strong>End Date:</strong></td><td style="text-align:left;color:#6e3b70">' +
        endDate +
        '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custMessage +
        '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#6e3b70;padding-left:5px">Ocean Delight</span>.</p></div></body></html>';
      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          toEmail: "oceandelight2022@gmail.com",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName: "Ocean-Delight",
          accountLeadSource: "https://oceandelight.co.in/",
          accountLeadName: custName,
          // accountLeadEmail: custEmail,
        },
        function (dataa, status) {
          console.log("data :" + dataa);
          console.log("name:" + custName);
          console.log("name:" + custEmail);
        }
      );

      alert("Your Form has Submitted Our team will contact with You  soon.");
      e.preventDefault();
      return false;
    }
  };
  return (
    <>
          {/* ========= Seo start========== */}
          {/* ========= Seo start========== */}
      <MetaTags>
        <title>Best Resorts in Bhogave Beach | Ocean Delight  </title>
        <meta name="title" content="Best Resorts in Bhogave Beach | Ocean Delight " />
        <meta property="og:title" content="Best Resorts in Bhogave Beach | Ocean Delight " />
        <meta name="description" content="Immerse yourself in serenity at the Best Resorts in Bhogave Beach. Find your haven of relaxation, where sun, sand, and luxury meet." />
       
        <meta property="og:description" content="Immerse yourself in serenity at the Best Resorts in Bhogave Beach. Find your haven of relaxation, where sun, sand, and luxury meet." />
        <meta name="keywords" content="Beach Resort in Bhogave,
 Best Beach Resort in Bhogave,
Best Resorts in Bhogave Beach,
 Best Resort Near Beach in Bhogave,
Resort Near Beach in Bhogave,
Resort in Bhogave" />
        <link rel="canonical" href="https://oceandelight.co.in/best-resorts-bhogave-beach" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== Room Banner ======== */}
      <div className="Room-banner">
        <div className="RoomBanner_Txt">

          <Zoom>
            <h1 style={{color:"#fff"}}>Best Resorts in Bhogave Beach</h1>
          </Zoom>
          {/* <p>
          Unwind with unmatched elegance at the best resort near the beach in Bhogave.
          </p> */}
        </div>
      </div>
      {/* ============== Room Section ========== */}
      <section className="room-section">
        <div className="container">
          <div className="site-heading text-center">
            <Bounce>
              <h2 className="site-title">Room Details</h2>
            </Bounce>
            <div className="header-img-divider">
              <img src={Arrow} alt="sunset beach resort  bhogave" />
            </div>
            {/* <h4>Glance at our great resort</h4> */}
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Swiper
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="room2"
              >
                <SwiperSlide>
                  <div className="roomImg-long">
                    <img src={Room1} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-long">
                    <img src={Room2} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-long">
                    <img src={Room3} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-long">
                    <img src={Room4} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-long">
                    <img src={Room5} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-long">
                    <img src={Room6} />
                  </div>
                </SwiperSlide>
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="room"
              >
                <SwiperSlide>
                  <div className="roomImg-short">
                    <img src={Room1} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-short">
                    <img src={Room2} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-short">
                    <img src={Room3} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-short">
                    <img src={Room4} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-short">
                    <img src={Room5} />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="roomImg-short">
                    <img src={Room6} />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-lg-6">
              <div className="roomDesc">
                <div className="roomTxt">
                  <h2>Description of Room</h2>
                  <p>
                  Step into a world of refined coastal elegance as you explore our diverse range of meticulously crafted rooms at the best beach resort in Bhogave. Each space is a testament to luxury, blending modern comfort with the serenity of beachfront living.


In the best beach resort in Bhogave, our suites offer panoramic views of the ocean, capturing the essence of a tranquil escape. Embrace the soothing ambiance with tasteful decor and a seamless fusion of indoor-outdoor living. Whether you choose a deluxe room, private villa, or exclusive penthouse, each accommodation reflects our commitment to providing a haven of relaxation.


Indulge in a restful night's sleep on premium bedding, surrounded by thoughtful amenities that cater to your every need. Immerse yourself in the gentle sounds of the sea from your private balcony or terrace, creating a personal retreat amidst nature's beauty.


Designed with both leisure and business travelers in mind, our rooms seamlessly blend functionality with style. High-speed internet, spacious work areas, and intuitive room layouts ensure a comfortable stay, whether you're here for leisure or business.


Pamper yourself in lavish bathrooms equipped with rejuvenating amenities, setting the stage for a day of exploration or unwinding. In the  best beach resort in Bhogave.Our commitment to sustainability extends to our rooms, with eco-friendly practices that allow you to enjoy luxury with a conscience.


Experience the epitome of coastal living in our best beach resort in Bhogave. rooms – where every detail is curated to enhance your stay and create lasting memories of seaside serenity and unparalleled comfort.

   </p>
                </div>
           
              </div>
            
            </div>
            <div className="col-12">
              <div className="room-aminites mt-5">
                  <h4 className="text-center">Room Amenities</h4>
                  <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-3 d-flex justify-content-center align-items-center">
                    <div className="col mb-md-3 mb-2 d-flex justify-content-center align-items-center">
                      <div className="aminities">
                        <span className="material-icons-outlined">wifi</span>
                        <h5>WIFI Services</h5>
                      </div>
                    </div>
                    <div className="col mb-md-3 mb-2 d-flex justify-content-center align-items-center">
                      <div className="aminities">
                        <span className="material-icons-outlined">
                        visibility
                        </span>
                        <h5>Sea View Rooms</h5>
                      </div>
                    </div>
                    <div className="col mb-md-3 mb-2 d-flex justify-content-center align-items-center">
                      <div className="aminities">
                        <span className="material-icons-outlined">
                          ondemand_video
                        </span>
                        <h5>Television</h5>
                      </div>
                    </div>
                    <div className="col mb-md-3 mb-2 d-flex justify-content-center align-items-center">
                      <div className="aminities">
                        <span className="material-icons-outlined">
                          restaurant
                        </span>
                        <h5>In room dining</h5>
                      </div>
                    </div>
                    <div className="col mb-md-3 mb-2 d-flex justify-content-center align-items-center">
                      <div className="aminities">
                        <span className="material-icons-outlined">
                          cleaning_services
                        </span>
                        <h5>Daily Housekeeping</h5>
                      </div>
                    </div>
                    <div className="col mb-md-3 mb-2 d-flex justify-content-center align-items-center">
                      <div className="aminities">
                        <span className="material-icons-outlined">
                          bedroom_parent
                        </span>
                        <h5>Air Conduction Room</h5>
                      </div>
                    </div>
                    <div className="col mb-md-3 mb-2 d-flex justify-content-center align-items-center">
                      <div className="aminities">
                        <span className="material-icons-outlined">
                        coffee_maker
                        </span>
                        <h5>Tea-Coffee Maker</h5>
                      </div>
                    </div>
                    <div className="col mb-md-3 mb-2 d-flex justify-content-center align-items-center">
                      <div className="aminities">
                        <span className="material-icons-outlined">
                        bathroom
                        </span>
                        <h5>Linens & Toiletries</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      {/* ========== Contact Address Section =========== */}
      <section className="room-contact">
        <div className="container">
          <div className="site-heading text-center">
            <Bounce>
              <h2 className="site-title">Stay Connection</h2>
            </Bounce>
            <div className="header-img-divider">
              <img src={Arrow} alt="sunset beach resort  bhogave" />
            </div>
            {/* <h4>Glance at our great resort</h4> */}
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Name*</label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={custName}
                          onChange={(e) => setCustName(e.target.value)}
                        />
                        {errors.custName && (
                          <span className="error-text">{errors.custName}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Email*</label>
                      <div className="input-group">
                        <input
                          class="form-control"
                          type="text"
                          name="email"
                          placeholder="Email"
                          value={custEmail}
                          onChange={(e) => setCustEmail(e.target.value)}
                        />
                        {errors.custEmail && (
                          <span className="error-text">{errors.custEmail}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Phone*</label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="number"
                          name="contact"
                          placeholder="Contact No"
                          value={custContact}
                          onChange={(e) => setCustContact(e.target.value)}
                        />
                        {errors.custContact && (
                          <span className="error-text">
                            {errors.custContact}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Total Person*</label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="number"
                          name="person"
                          placeholder="Total People"
                          value={custPerson}
                          onChange={(e) => setCustPerson(e.target.value)}
                        />
                        {errors.custContact && (
                          <span className="error-text">
                            {errors.custPerson}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Check In*</label>
                      <div className="input-group">
                        <DatePicker
                          selected={startDate}
                          className="form-control"
                          value={startDate}
                          onChange={(StartDate) => setStartDate(StartDate)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">Check Out*</label>
                      <div className="input-group">
                        <DatePicker
                          selected={endDate}
                          className="form-control"
                          value={endDate}
                          onChange={(EndDate) => setEndDate(EndDate)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="form-label">Message*</label>
                  <div className="input-group">
                    <textarea
                      class="form-control"
                      name="message"
                      placeholder="Message"
                      id="custMessage1"
                      value={custMessage}
                      rows={6}
                      onChange={(e) => setCustMessage(e.target.value)}
                    ></textarea>
                    {errors.custMessage && (
                      <span className="error-text">{errors.custMessage}</span>
                    )}
                  </div>
                </div>
                <button className="booking-btn" onClick={ServForm}>
                  Submit
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3836.197161566034!2d73.504473!3d15.951077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc00123000b7c61%3A0x7625089222d0bd77!2sOcean%20Delight%20-%20The%20Beach%20Resort!5e0!3m2!1sen!2sin!4v1696500911062!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Room;
