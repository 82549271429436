import React from "react";
import Zoom from "react-reveal/Zoom";
const Items = ({ data }) => {
  return (
    <>
    <div className="GalleryItems">
    <div className="row">
        {data.map((value) => {
          const { id, EImage, EventName } = value;
          return (
            <>
              <Zoom>
                <div className=" col-lg-3 col-md-4 col-sm-6 my-3" key={id}>
                  <div className="cards text-center">
                    <div className="GalleryItem-cardImg">
                    <img src={EImage} />
                    </div>
                    {/* <div className="GalleryItem-cardTxt">
                      <h4>{EventName}</h4>
                    </div> */}
                  </div>
                </div>
                {/* <div className="col-sm-4 my-3" key={id}>
                  <div className="GalleryItem-cards">
                    <div className="GalleryItem-cardImg">
                      <img src={EImage} />
                      <div className="GalleryItem-cardTxt">
                        <h4>{EventName}</h4>
                      </div>
                    </div>
                  </div>
                </div> */}
              </Zoom>
            </>
          );
        })}
      </div>
    </div>
    </>
  );
};

export default Items;
