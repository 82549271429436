import React, { useEffect } from "react";
import "../Css/Menu.css";
import Zoom from "react-reveal/Zoom";
import Bounce from "react-reveal/Bounce";
import { Link } from "react-router-dom";
import Food1 from "../img/food/food1.jpg";
import Food2 from "../img/food/food2.jpg";
import Food3 from "../img/food/food3.jpg";
import Food4 from "../img/food/food4.jpg";
import Food5 from "../img/food/food5.jpg";
import Food6 from "../img/food/food6.jpg";
import Food7 from "../img/food/food7.jpg";
import Footer from "../Components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { MetaTags } from "react-meta-tags";
import Arrow from "../img/arrow.webp";
import Menupage01 from "../img/menus/menu1.jpg";
import Menupage02 from "../img/menus/menu2.jpg";
import Menupage03 from "../img/menus/menu3.jpg";
import Menupage04 from "../img/menus/menu4.jpg";
import Menupage05 from "../img/menus/menu5.jpg";
import Menupage06 from "../img/menus/menu6.jpg";

const Menu = () => {
  useEffect(() => {
    const pages = document.getElementsByClassName("page");
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      if (i % 2 === 0) {
        page.style.zIndex = pages.length - i;
      }
    }

    for (let i = 0; i < pages.length; i++) {
      pages[i].pageNum = i + 1;
      pages[i].addEventListener("click", function () {
        if (this.pageNum % 2 === 0) {
          this.classList.remove("flipped");
          this.previousElementSibling.classList.remove("flipped");
        } else {
          this.classList.add("flipped");
          this.nextElementSibling.classList.add("flipped");
        }
      });
    }
  }, []);
  return (
    <>
          {/* ========= Seo start========== */}
          {/* ========= Seo start========== */}
      <MetaTags>
        <title> Best Resort Near Beach in Bhogave | Ocean Delight  </title>
        <meta name="title" content=" Best Resort Near Beach in Bhogave | Ocean Delight  " />
        <meta property="og:title" content=" Best Resort Near Beach in Bhogave | Ocean Delight  " />
        <meta name="description" content="Discover the Best Resort near the beach in Bhogave for a dreamy seaside escape. Book your stay now for sun, sand, and serenity!" />
       
        <meta property="og:description" content="Discover the Best Resort near the beach in Bhogave for a dreamy seaside escape. Book your stay now for sun, sand, and serenity!" />
        <meta name="keywords" content="Beach Resort in Bhogave,
 Best Beach Resort in Bhogave,
Best Resorts in Bhogave Beach,
 Best Resort Near Beach in Bhogave,
Resort Near Beach in Bhogave,
Resort in Bhogave" />
        <link rel="canonical" href="https://oceandelight.co.in/best-resort-near-beach-bhogave" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== Menus Banner ======== */}
      <div className="Menu-banner">
        <div className="MenuBanner_Txt">
          {/* <h1 className="d-none">Menu</h1> */}
          <Zoom>
            <h1 style={{color:"#fff"}}> Best Resort Near Beach in Bhogave</h1>
          </Zoom>
          {/* <p>
          Be our guest and enjoy the meal at our best resort near the beach in Bhogave.
          </p> */}
        </div>
      </div>
      {/* =========== menu Section ========== */}


      {/* =========== Menu Section ========== */}
      <section className="menuSection">
        <div className="container">
          <div className="site-heading text-center">
            <Bounce>
              <h2 className="site-title">Food Menu</h2>
            </Bounce>
            <div className="header-img-divider">
              <img src={Arrow} alt="resort near beach" />
            </div>
            {/* <h4>Glance at our great resort</h4> */}
          </div>
          <div className="menu_desc mb-5">
          <p>Indulge your senses in a culinary journey that mirrors the beauty of the surrounding ocean at our best resort near beach in Bhogave. Our menu, meticulously crafted by expert chefs, showcases a symphony of flavors inspired by the bounty of the sea and the vibrant local ingredients.
</p>
          <p>Start your day with a sun-kissed breakfast overlooking the waves, featuring an array of freshly baked pastries, tropical fruits, and artisanal coffee. As the day unfolds, our best resort near beach in Bhogave, restaurants invite you to savor a diverse menu, offering a fusion of international and regional cuisines. From succulent seafood delicacies to enticing vegetarian options, every dish is a celebration of taste and quality.
</p>
          <p>Join the best resort near beach in Bhogave for a leisurely lunch by the pool, where refreshing salads and grilled specialties are served alongside handcrafted cocktails. Our commitment to sourcing local, sustainable ingredients ensures that each bite not only delights your palate but also supports the community and the environment.</p>
          
          <p>As the sun dips below the horizon, our dinner offerings elevate your dining experience. Choose from an array of exquisitely prepared dishes, including fresh catches of the day, expertly grilled meats, and vegetarian delights. Immerse yourself in the ambient glow of our seaside setting, enhanced by attentive service that caters to your every need.


Complete your culinary adventure with decadent desserts, crafted to satisfy your sweet cravings. In Our best resort near beach in Bhogave, our dessert menu features a tempting selection of treats, from tropical fruit-infused delights to classic indulgences that provide the perfect finale to your dining experience.


Whether you're seeking a casual beachside bite, a romantic dinner for two, or a celebratory feast with friends and family, our menu promises an unforgettable gastronomic journey that captures the essence of coastal living. Welcome to a world where each meal is a celebration of the sea's bounty and the artistry of culinary craftsmanship.


</p></div>
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className="brackfast"
          >
             <SwiperSlide>
              <div className="menuImg">
                <img src={Food1} alt="resort near beach of southern food" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="menuImg">
                <img src={Food2} alt="beach near resort of southern food" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="menuImg">
                <img src={Food3} alt="beach near resort" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="menuImg">
                <img src={Food4} alt="beach near resort" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="menuImg">
                <img src={Food5} alt="beach near resort" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="menuImg">
                <img src={Food6} alt="beach near resort" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="menuImg">
                <img src={Food7} alt="beach near resort" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <>
        {/* =========== menu Section3 ========== */}
        <section className="Menu-Section-3">
          <div className="container">
            <div className="Menu-Section-3-con">
              <div class="book">
                <div id="pages" class="pages">
                  <div class="page">
                    <img src={Menupage01} alt="menu for ocean delight resort" />
                  </div>
                  <div class="page">
                    <img src={Menupage02} alt="menu for ocean delight resort" />
                  </div>
                  <div class="page">
                    <img src={Menupage03} alt="menu for ocean delight resort" />
                  </div>
                  <div class="page">
                    <img src={Menupage04} alt="menu for ocean delight resort" />
                  </div>
                  <div class="page">
                    <img src={Menupage05} alt="menu for ocean delight resort" />
                  </div>
                  <div class="page">
                    <img src={Menupage06} alt="menu for ocean delight resort" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Footer />
    </>
  );
};

export default Menu;
