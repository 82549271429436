import React, { useState,useLayoutEffect } from 'react';
import "../Css/Gallery.css";
import GalleryData from "../Components/GalleryData";
import { Link} from "react-router-dom";
import Footer from "../Components/Footer";
import Tabs from "./Tabs";
import Items from "./Items";
import { MetaTags } from 'react-meta-tags';
import { Bounce } from 'react-reveal';
import Zoom from "react-reveal/Zoom";
import Arrow from "../img/arrow.webp";



const Gallery = ({}) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  const [data, setData] = useState(GalleryData)

  // Store Category in CategoryData 
  const categoryData = GalleryData.map((value) => {
    return value.Category
  });
  ////////////

  const tabsData = ["all", ...new Set(categoryData)];


  // for all categoryData set 
  const filterCategory = (Category) => {
    if (Category == "all") {
      setData(GalleryData)
      return;
    }
    const filteredData = GalleryData.filter((value) => {
      return value.Category == Category;
    })

    setData(filteredData);

  }


  return (
    <>
      {/* ========= Seo start========== */}
        {/* ========= Seo start========== */}
        <MetaTags>
        <title>Resort Near Beach in Bhogave | Ocean Delight  </title>
        <meta name="title" content="Resort Near Beach in Bhogave | Ocean Delight " />
        <meta property="og:title" content="Resort Near Beach in Bhogave | Ocean Delight " />
        <meta name="description" content="Experience paradise at the Best Resort near Bhogave Beach. Unwind in tranquil surroundings, pristine shores, and world-class amenities." />
       
        <meta property="og:description" content="Experience paradise at the Best Resort near Bhogave Beach. Unwind in tranquil surroundings, pristine shores, and world-class amenities." />
        <meta name="keywords" content="Beach Resort in Bhogave,
 Best Beach Resort in Bhogave,
Best Resorts in Bhogave Beach,
 Best Resort Near Beach in Bhogave,
Resort Near Beach in Bhogave,
Resort in Bhogave" />
        <link rel="canonical" href="https://oceandelight.co.in/Resort Near Beach in Bhogave" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== Gallery Banner ==== */}
      <div className="Gallery-banner">
      <div className="GalleryBanner-Txt">
        {/* <h1 className="d-none">Gallery</h1> */}
        <Zoom><h1 style={{color:"#fff"}}> Resort Near Beach in Bhogave</h1></Zoom>
          {/* <p>Indulge in the infinite at our resort near the beach in Bhogave.</p> */}
        </div>
      </div>
      {/* =========== Gallery Section =========== */}
      <section className='Gallery-Section'>
        <div className='Gallery-Card'>
          <div className='container'>
          <div className="site-heading text-center">
            <h2 className="site-title">Our Gallery</h2>
            <p>Welcome to our Resort near beach in Bhogave,, a visual odyssey capturing the essence of our beach resort's enchanting beauty and unforgettable experiences. Immerse yourself in a tapestry of images that reflect the seamless blend of luxury, nature, and coastal charm that defines our haven.


Explore the stunning vistas that unfold from every corner of our Resort near beach in Bhogave. From sun-drenched beaches to lush tropical gardens, each frame encapsulates the serene backdrop against which your memories are crafted. Marvel at the architectural elegance of our accommodations, where modern design harmonizes with the natural landscape, providing a sanctuary of comfort and style.


Dive into the azure waters of our pool or unwind in the shade of swaying palm trees. The Gallery showcases the vibrant spectrum of activities available, from exhilarating water sports to leisurely strolls along the shoreline. Picture yourself basking in the warmth of the sun, a testament to the idyllic setting that awaits your arrival.


Our culinary offerings take center stage, with mouthwatering images capturing the artistry of our chefs. From delectable breakfast spreads overlooking the ocean to candlelit dinners under the stars, each photograph tells a story of culinary excellence and gastronomic indulgence.


Experience the joyous celebrations that unfold within our Resort near beach in Bhogave – be it a romantic beach wedding, a family gathering by the fire pit, or a lively beach party. Our Gallery encapsulates the diverse range of events and occasions that find a perfect home within our coastal retreat.


At the heart of our Gallery is the warmth and hospitality that define our brand. Witness the genuine smiles of our staff, committed to ensuring your stay is nothing short of exceptional. Every image reflects the passion we have for creating moments that linger in your memory, inviting you to be a part of our story.


As you navigate through our Gallery, envision yourself immersed in the unparalleled beauty and experiences that await you at our Resort near beach in Bhogave. Welcome to a visual journey that encapsulates the spirit of coastal living, where every frame tells a tale of serenity, adventure, and timeless moments.
</p>
            <div className="header-img-divider">
              <img src={Arrow} alt="paradise best resort" />
            </div>
            {/* <h4>Glance at our great resort</h4> */}
          </div>
          <div className="galleryDesc mb-5">
            <p>Explore our resort’s beauty and splendor through our stunning photo gallery. From the sparkling blue waters of our Sea to the surrounding crystal white sands, our resort offers breathtaking landscapes and luxurious accommodations. Whether you’re seeking relaxation or adventure, we have everything you need to make your vacation unforgettable. Browse our gallery and start planning your stay at Ocean Delight today.</p>
          </div>
            <div className='row'>
              <div className='col-md-12'>
                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                <Items data={data} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Gallery;
