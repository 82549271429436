import React from "react";
import "../Css/Footer.css";
import { Link } from "react-router-dom";
import Logo from "../img/logo.jpeg";
import term from "../img/OCEAN DELIGHT - Rules and Regulations v1.1.pdf"
const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="footer-about">
                <div className="footer-logo">
                  <Link to="/">
                  <img src={Logo} width={180} alt="logo" />
                  </Link>
                </div>
                <div className="footer-desc">
                  <p>
                  This is the place where you can unwind on the beautiful and quiet beach, play in the waves of calm waters of the Arabian Sea or relax on our hammocks reading your favourite books.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="footer-link">
                <div className="footer-head">
                  <h4>Useful Link</h4>
                </div>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/best-beach-resort-bhogave">About</Link>
                  </li>
                  <li>
                    <Link to="/best-resort-near-beach-bhogave">Menu</Link>
                  </li>
                  <li>
                    <Link to="/resort-near-beach-bhogave">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/resort-in-bhogave">Contact</Link>
                  </li>
                  <li>
                    <a href={term}>Hotel Rules & Policies</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-2 col-sm-6">
              <div className="footer-link">
              <div className="footer-head">
                  <h4>Room Details</h4>
                </div>
                <ul>
                  <li>
                    <Link to="/Room">Room</Link>
                  </li>
                  <li>
                    <Link to="/">Activities</Link>
                  </li>
                  <li>
                    <Link to="/">Testimonal</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-3 col-sm-6">
              <div className="footer-address">
                <div className="footer-head">
                  <h4>Get in Touch</h4>
                </div>
                <ul>
                  <li>
                    <i className="fa fa-map-marker" />
                    <span>
                    At Post Bhogwe, Nr Mahapurush Mandir, Dist, Tal, Bhogwe, Maharashtra 416523
                    </span>
                  </li>
                  <li className="mt-2">
                    <i className="fa fa-phone" />{" "}
                    <span>
                      <a href="tel:+919820592518">+91-9820592518</a>
                    </span>
                  </li>
                  <li className="mt-2">
                    <i className="fa fa-envelope" />{" "}
                    <span>
                      <a href="mailto:oceandelight2022@gmail.com">
                        oceandelight2022@gmail.com
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="footer-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3836.197161566034!2d73.504473!3d15.951077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc00123000b7c61%3A0x7625089222d0bd77!2sOcean%20Delight%20-%20The%20Beach%20Resort!5e0!3m2!1sen!2sin!4v1696500911062!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="Footer_copyRight">
          <div className="container">
            <div className="row">
              <div class="col-md-6">
                <div class="copyright">
                  <p>
                    Copyright © 2023 All Rights Reserved :{" "}
                    <span>Ocean Delight</span>{" "}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="develope-by">
                  <p>
                    Designed by :
                    <a href="https://skdm.in/">
                      {" "}
                      <span>Shree Krishna Digital Marketing</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
